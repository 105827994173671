<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form class="search-form" ref="form" inline :model="searchData" label-width="">
      <el-form-item label="商户名称">
        <el-input style="width: 200px" v-model="searchData.name" size="small" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input style="width: 200px" v-model="searchData.mobile" size="small" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="地址">
        <el-input style="width: 200px" v-model="searchData.address" size="small" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="name" label="商户名称" min-width="80"> </el-table-column>
      <el-table-column prop="type" label="类型" min-width="130"> </el-table-column>
      <el-table-column prop="address" label="地址" min-width="100"> </el-table-column>
      <el-table-column prop="mobile" label="联系方式" min-width="80"> </el-table-column>
      <el-table-column prop="wechat" label="微信号" min-width="80"> </el-table-column>
      <el-table-column prop="createTime" label="提交时间" min-width="80"> </el-table-column>

      <el-table-column prop="id" label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" type="primary" :underline="false" @click="showDetail(row)">详情</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pagesize" @pagination="getList" />
    <!-- 详情弹窗 -->
    <el-drawer :visible.sync="show_detail" direction="rtl" size="50%">
      <Detail ref="detail" :detail="detail"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail },
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pagesize: 10,
        name: '',
        address: '',
        mobile: ''
      },
      total: 0,
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    async getList() {
      const res = await getListAPI(this.searchData)
      this.list = res.records || []
      this.total = res.totalRow
    },

    // 详情按钮
    async showDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-divider {
    margin-top: 0;
  }
  .search-form {
    margin-bottom: 20px;
  }
  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;
    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }
    .active {
      color: #409eff;
      font-weight: 600;
      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
  // 用户信息
  .user {
    display: flex;
    align-items: center;
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
      .nickname {
        display: flex;
        span {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
